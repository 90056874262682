<template>
    <div class="drops-wrapper">
         <div class="drops">
             <Drop v-for="drop in getDrops" :key="drop.id" :drop="drop"/>
         </div>
    </div>
</template>

<script>
import Drop from './Drop.vue'
export default {
    components : {
        Drop
    },
    computed : {
        getDrops(){
            return this.$store.getters.getDrops
        }
    },
    // mounted(){
    //     this.$store.commit("setDrops")
    // }
}
</script>

<style lang="scss" scoped>
    .drops-wrapper{
        padding: 0 100px;
        padding-bottom: 263px;
        .drops{
            display: flex;
            flex-wrap: wrap;
            gap: 60px;
        }
    }
</style>