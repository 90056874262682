<template>
  <section id="exhibition">
    <div class="container">
      <h3 class="title">Exhibition</h3>
      <div class="exhibition-info">
          <ExhibitionInfo v-for="item in info" :key="item.title" :title="item.title" :desc="item.desc"/>
      </div>
    </div>
  </section>
</template>

<script>
import ExhibitionInfo from "./ExhibitionInfo.vue";
export default {
  data() {
    return {
      info: [
        {
          title: "About Art Dubai",
          desc: [
            "Art Dubai is the premier platform to see and buy modern and contemporary art from the Global South. Featuring Contemporary and Modern gallery sections, annual artist commissions and year-round collector and education programmes, Art Dubai champions art and artists from across the Global South, providing a relevant and increasingly important alternative to mainstream, largely Western-lednarratives.Art Dubai is held in partnership with A.R.M. Holding.",
            "The fair is sponsored by Swiss Wealth Management Group Julius Baer. The Dubai Culture & Arts Authority (Dubai Culture) is the fair’s strategic partner. Bybit isthe lead partner of Art Dubai Digital. Art Dubai’s 15th edition will take place from March 11-13, 2022 at @madinatjumeirah with its most extensive edition to date.Featuring over 100 exhibitors representing 44 countries, this year’s programming will feature internationally renowned artists, a new digital section that bridges the art and crypto world including NFT’s,video art and virtual reality (VR), along with ground-breaking exhibitions and talks.",
          ],
        },
        {
          title: "What is Campus Art Dubai?",
          desc: [
            "Launched in 2012 as the first programme of its kind in the region, Campus Art Dubai (CAD) is part of Art Dubai’s extensive non-commercial programming that continuously develops each year to meet the changing needs of the UAE’s creative community. Dubai Culture & Arts Authority is the Strategic Partner of Campus Art Dubai",
            "Campus Art Dubai 9.0 Blockchain Edition, is the 2022 edition, powered this year by Materia. Through its 8-week exclusive programme for artists with experience in the fields of photography, videography, visual art, animation, sound or new media art, it aimed to provide the students with an in-depth overview of the NFT world, the blockchain applications for art, the role of cryptocurrencies and different tokens.",
            "All applicants presented an NFT Project to be developed during the programme which resulted in an NFT artwork presented at Art Dubai where you can visit the Campus Art Dubai exhibition.",
          ],
        },
      ],
    };
  },
  components: {
    ExhibitionInfo,
  },
};
</script>

<style lang="scss" scoped>
    .exhibition-info{
        .info{
            &:not(:first-child){
                margin-top: 50px;
            }
        }
    }
</style>
