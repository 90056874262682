<template>
    <section id="banner">
        <figure>
            <img src="../assets/images/banner.png" alt="banner">
        </figure>
    </section>
</template>

<style lang="scss" scoped>
    #banner {
        figure {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }
</style>