<template>
    <main>
        <Banner/>
        <Exhibition/>
        <Artworks/>
    </main>
</template>

<script>
import Banner from './Banner.vue'
import Exhibition from './Exhibition.vue'
import Artworks from './Artworks.vue'

export default {
    components : {
        Banner,
    Exhibition,
    Artworks
    }
}
</script>