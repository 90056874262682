<template>
    <section id="artworks">
        <div class="container">
            <h3 class="title">Artworks</h3>
            <Drops/>
        </div>
    </section>
</template>

<script>
import Drops from './Drops.vue'
export default {
    components : {
        Drops
    }
}
</script>