<template>
    <div class="info">
        <h4>{{title}}</h4>
        <p v-for="(item,index) in desc" :key="index">{{item}}</p>
    </div>
</template>

<script>
export default {
    props : ['title',"desc"]
}
</script>

<style lang="scss" scoped>
    h4{
        font-family: Aventa;
        font-weight: 400;
        font-size: 35px;
        line-height: 24px;
        color: #000;
    }
    p{
        font-family: Aventa;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000;
        margin: 30px 0;
    }
</style>