<template>
    <router-link :to="'/drop/'+drop.id" target="_blank">
        <div class="drop">
            <figure>
                <img :src="getImgUrl(drop.thumbnail)" :alt="drop.title">
                <img v-if="drop.type === 'video'" class="play" src="../assets/icons/play.svg" alt="">
            </figure>
            <div class="drop-info">
                <div class="drop-text">
                    <h4>{{drop.title}}</h4>
                    <p>{{drop.created}}</p>
                </div>
                <div class="drop-author">
                    <figure>
                        <img :src="getImgUrlAvatar()" alt="">
                    </figure>
                    <p>By <span>{{drop.by}}</span></p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props : ["drop"],
    methods : {
        getImgUrl(pic) {
            return require('../assets/images/drops/'+pic)
        },
        getImgUrlAvatar() {
            const author = this.$store.getters.getUsers
            const user = author.filter(by=>Number(by.id) === Number(this.drop.authorId))
            return require('../assets/images/avatar/'+user[0].avatar)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixins.scss";
    a{
        flex-basis: 28%;
        .drop{
        height: 445px;
        padding: 35px 0;
        background: #FFFFFF;
        box-shadow: 0px -2px 18px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        figure{
            position: relative;
            width: 100%;
            img{
                &:first-child{
                    width: 100%;
                }
            }
        }
        .play{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        .drop-info{
            
            margin-top: 8px;
            padding: 0 22px;
            .drop-text{
            padding-bottom: 10px;
            h4{
                @include text(14px,23px);
                color: #000;
            }
            p{
                @include text(12px,24px);
                color: #000;
            }
            }
            
        }
        .drop-author{
            border-top : 0.25px solid #C9C9C9;
            padding-top: 15px;
            display: flex;
            align-items: center;
            figure{
                width: 50px;
                height: 50px;
                margin-right: 10px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
            p,span{
                @include text(12px,24px);
                color: #000;
            }
        }
    }
    }
</style>